import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {totpauthenticate} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {useIntl} from 'react-intl'
import * as Auth from '../../../store/ducks/auth.duck'
import {useAuth} from '../core/Auth'

// import {ElectricalServicesSharp} from '@mui/icons-material'

const initialValues = {
  totp: ''
}

export function Totp() {
  const intl = useIntl()
  const authTotp = useSelector((state: any) => state.auth.aToken)
  const [errorInformation, setErrorInformation] = useState<string | false>()
  console.log('authTotp')
  console.log(authTotp)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        console.log('authTotp')
        console.log(authTotp)
        const {data: auth} = await totpauthenticate(values.totp, authTotp)
        dispatch(Auth.actions.login(auth))
        dispatch(Auth.actions.getVHLogin(auth.member_key, auth.token, auth.email))
      } catch (err: any) {
        // console.log(err}
        //console.log(err.response.data)
        // console.log(err.response.status)
        // const message = '<br> Hello World <br>'
        setSubmitting(false)
        setLoading(false)
        if (err) {
          if (err.response) {
            if (err.response.data.message && err.response.data.message === 'Bad') {
              setStatus(
                intl.formatMessage({
                  id: 'AUTH.VALIDATION.SERVER_OUTAGE'
                })
              )
            } else {
              setStatus(
                intl.formatMessage({
                  id: 'AUTH.VALIDATION.INVALID_LOGIN'
                })
              )
            }
          }
        } else {
          setStatus(
            intl.formatMessage({
              id: 'AUTH.VALIDATION.SERVER_OUTAGE'
            })
          )
        }
        saveAuth(undefined)
        // setStatus('The login details are incorrect')
      }
    }
  })

  return (
    <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate id='kt_login_password_reset_form' onSubmit={formik.handleSubmit}>
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Additional Authentication</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>Enter the email code</div>
        {/* end::Link */}
      </div>

      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{errorInformation}</div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-primary p-8 rounded'>
          <div className='text-primary'> Please check your email</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <input
          type='totp'
          placeholder='Email Code'
          autoComplete='off'
          {...formik.getFieldProps('totp')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.totp && formik.errors.totp},
            {
              'is-valid': formik.touched.totp && !formik.errors.totp
            }
          )}
        />
        {formik.touched.totp && formik.errors.totp && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.totp}</span>
            </div>
          </div>
        )}
      </div>

      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button type='button' id='kt_login_password_reset_form_cancel_button' className='btn btn-light' disabled={formik.isSubmitting || !formik.isValid}>
            Cancel
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
