import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import MessagesForm from '../forms/Messages'

export default function ClaimFormDashgboard() {
  return (
    <DashboardBox>
      <DashboardSection name={'Review your Recent SecureForms and Messages'}>
        <MessagesForm />
      </DashboardSection>
    </DashboardBox>
  )
}
