import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {Button} from '@mui/material'
import {StyledTableCell, StyledTableRow} from '.'
import TableCellText from './TableCellText'
import {Incentive} from './IncentivesHistoryTable'

interface IncentiveMobileTableProps {
  onClickDownload: (_: any) => any
  data: Array<Incentive>
  year: string
}

type MobileTableProps = IncentiveMobileTableProps

export default function DesktopTable({onClickDownload, data, year}: MobileTableProps) {
  //
  const yearData = data.filter((incentive) => incentive.incentiveyear === year)
  return (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 700}} aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Incentive Date</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Reason</StyledTableCell>
            <StyledTableCell>View details</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {yearData &&
            yearData.map((row: Incentive, i: number) => (
              <StyledTableRow key={i}>
                <StyledTableCell component='th' scope='row'>
                  {row.created}
                </StyledTableCell>
                <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                  <TableCellText text={row.type} />
                </StyledTableCell>
                <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                  <TableCellText text={row.reason} />
                </StyledTableCell>
                <StyledTableCell>
                  <div style={{width: '160px'}}>
                    <Button variant='contained' size='small' disableElevation className='rounded-full' onClick={() => onClickDownload(row)}>
                      Download Claim
                    </Button>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
