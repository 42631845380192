import axios from 'axios'
import {UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const BASE_URL = process.env.REACT_APP_APIIP

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${BASE_URL}/users/register`
export const LOST_PASSWORD_URL = `${BASE_URL}/users/lostpassword`
export const UPDATE_PASSWORD_URL = `${BASE_URL}/users/newpassword`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post(BASE_URL + '/users/authenticate', {username, password})
}

export function totpauthenticate(totp: string, AToken: string) {
  return axios.post(BASE_URL + '/users/totpauthenticate', {totp, AToken})
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string, lastname: string, ssn4: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
    lastname,
    ssn4
  })
}

export function lostPassword(email: string, lastname: string, ssn4: string, pagetype: string) {
  return axios.post<{result: boolean}>(LOST_PASSWORD_URL, {
    email,
    lastname,
    ssn4,
    pagetype
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token
  })
}

export function updatePassword(userName: string, newPass: string, registerguid: string, lostpasswordguid: string, resetguid: string) {
  return axios.put<{result: boolean}>(UPDATE_PASSWORD_URL, {
    userName,
    registerguid,
    newPass,
    lostpasswordguid,
    resetguid
  })
}
