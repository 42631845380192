import {FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import Dashboard from '../pages/home/Dashboard'
import NewsDashboard from '../pages/navigation/NewsDashboard'
import BlogDashboard from '../pages/navigation/BlogDashboard'
import MyPlanDashboard from '../pages/navigation/MyPlanDashboard'
import PaymentsDashboard from '../pages/navigation/PaymentsDashboard'
import ClaimsHistoryDashboard from '../pages/navigation/ClaimsHistoryDashboard'
import IncentivesHistoryDashboard from '../pages/navigation/IncentivesHistoryDashboard'
import Request1095BHistoryDashboard from '../pages/navigation/Request1095BHistoryDashboard'
import ClaimFormDashboard from '../pages/navigation/ClaimFormDashboard'
import DirectDebitDashboard from '../pages/navigation/DirectDebitFormDashboard'
import IncentiveFormDashboard from '../pages/navigation/IncentiveFormDashboard'
import DisabilityApplicationDashboard from '../pages/navigation/DisabilityApplicationDashboard'
import Request1095BFormDashboard from '../pages/navigation/Request1095BFormDashboard'
import SecureFormDashboard from '../pages/navigation/SecureFormDashboard'
import ProfileFormDashboard from '../pages/navigation/ProfileFormDashboard'
import PasswordUpdateFormDashboard from '../pages/navigation/PasswordUpdateFormDashboard'
import MakePaymentsDashboard from '../pages/navigation/MakePaymentsDashboard'
import SecureFormMessagesDashboard from '../pages/navigation/SecureFormMessagesDashboard'
import {Logout} from '../modules/auth/Logout'

import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

import '../../_metronic/assets/css/app.css'
import '../../_metronic/assets/css/app2.css'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/home' />} />

        {/* Pages */}
        <Route path='home' element={<Dashboard />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='news' element={<NewsDashboard />} />
        <Route path='blogs' element={<BlogDashboard />} />
        <Route path='my-plans' element={<MyPlanDashboard />} />
        <Route path='payments' element={<PaymentsDashboard />} />
        <Route path='claims-history' element={<ClaimsHistoryDashboard />} />
        <Route path='incentives-history' element={<IncentivesHistoryDashboard />} />
        <Route path='request1095b-history' element={<Request1095BHistoryDashboard />} />
        <Route path='submit-claim' element={<ClaimFormDashboard />} />
        <Route path='submit-incentive' element={<IncentiveFormDashboard />} />
        <Route path='submit-1095B' element={<Request1095BFormDashboard />} />
        <Route path='submit-secureform' element={<SecureFormDashboard />} />
        <Route path='profileform' element={<ProfileFormDashboard />} />
        <Route path='passwordupdateform' element={<PasswordUpdateFormDashboard />} />
        <Route path='makepayment' element={<MakePaymentsDashboard />} />
        <Route path='messages' element={<SecureFormMessagesDashboard />} />
        <Route path='disabilityenrollment' element={<DisabilityApplicationDashboard />} />
        <Route path='directdebit' element={<DirectDebitDashboard />} />

        <Route path='logout' element={<Logout />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
