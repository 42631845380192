import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import DirectDebitForm from '../forms/DirectDebitForm.js'

export default function ClaimFormDashgboard() {
  return (
    <DashboardBox>
      <DashboardSection name={'Authorization Agreement for Automatic Debits (ACH Debits)'}>
        <DirectDebitForm />
      </DashboardSection>
    </DashboardBox>
  )
}
