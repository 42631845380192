import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Grid, Divider, Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, Box, Checkbox} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
// import Typography from '@mui/material/Typography'
// import Alert from '@mui/material/Alert'
// import FormHelperText from '@mui/material/FormHelperText'
import Spinner from 'react-bootstrap/Spinner'
import {Formik, useFormikContext} from 'formik'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import {States} from '../../components/Misc/States'
// import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
// import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
//  import dayjs from 'dayjs'
import * as Yup from 'yup'
import {FormLabel} from 'react-bootstrap'

export default function DirectDebitForm() {
  // Define a few variables
  const dispatch = useDispatch()

  // Need to load existing direct debit settings
  useEffect(() => {
    dispatch(Member.actions.fetchDirectDebit())
  }, [dispatch])

  const directDebit = useSelector((state) => state.member.DirectDebit)
  const returnStatus = useSelector((state) => state.member.ReturnStatus)

  // Set form type default
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function allowMoreEditing() {
    // Close Submitting Modal
    setOpen(false)
    // Reactivate submit for another attempt
    setIsLoading(false)
  }

  // console.log(isLoading);

  function Savesubmit(data) {
    // Open Submitting modal
    setOpen(true)
    setIsLoading(true)
    // Some debugging
    if (process.env.REACT_APP_DEBUG) {
      console.log(data)
    }
    // Save request/form
    dispatch(Member.actions.putDirectDebitFormData(data))
  }

  //   apiResponse.id =System.Convert.ToInt32(dr["id"]);

  //   apiResponse.Status = dr["status"].ToString();
  //   apiResponse.Last_name = dr["last_name"].ToString();
  //   apiResponse.ssn_last4 = dr["ssn_last4"].ToString();

  function FormBankNameSelector() {
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'bank_name'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={3}>
          <FormLabel className='gray-1-text' id='bankname-label'>
            Bank Name
          </FormLabel>
        </Grid>
        <Grid item xs={6} md={9}>
          <TextField
            type='text'
            className={'mt-0 mb-0'}
            style={{minWidth: '100%'}}
            variant='outlined'
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
      </>
    )
  }

  function FormBankCitySelector() {
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'bank_city'
    //
    return (
      <>
        <Grid item className={'mt-2'} xs={12} md={3}>
          <FormLabel className='gray-1-text' id='bankcity-label'>
            City
          </FormLabel>
        </Grid>
        <Grid item xs={6} md={9}>
          <TextField
            type='text'
            className={'mt-0 mb-0'}
            style={{minWidth: '100%'}}
            variant='outlined'
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
      </>
    )
  }

  function FormBankStateSelector() {
    const {setFieldValue, values, touched, errors} = useFormikContext()
    //
    // This question appears for all claim form types
    //
    const fieldName = 'bank_state'
    //
    return (
      <>
        <Grid item className={'mt-2'} xs={12} md={3}>
          <FormLabel className='gray-1-text' id='bankstate'>
            State
          </FormLabel>
        </Grid>
        <Grid item xs={6} md={9}>
          <Autocomplete
            id='statelookup'
            disableClearable
            options={States}
            value={States.find((v) => v.id === values[fieldName]) || null}
            onChange={(e, value) => {
              setFieldValue(fieldName, value.id)
            }}
            getOptionLabel={(option) => option.name}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                type='text'
                variant='outlined'
                margin='normal'
                id='state'
                name='state'
                className={'mt-0 w-100'}
                helperText={touched[fieldName] ? errors[fieldName] : ''}
                error={touched[fieldName] && Boolean(errors[fieldName])}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                  'aria-label': 'State'
                }}
              />
            )}
          />
        </Grid>
      </>
    )
  }

  function FormBankABANumSelector() {
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'aba_num'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={3}>
          <FormLabel className='gray-1-text' id='abanumber-label'>
            Routing Number
          </FormLabel>
        </Grid>
        <Grid item xs={6} md={9}>
          <TextField
            type='text'
            className={'mt-0 mb-0'}
            style={{minWidth: '100%'}}
            variant='outlined'
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
      </>
    )
  }

  function FormBankAccntNumSelector() {
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'acct_num'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={3}>
          <FormLabel className='gray-1-text' id='account number-label'>
            Account Number
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={9}>
          <TextField
            type='text'
            className={'mt-0 mb-0'}
            style={{minWidth: '100%'}}
            variant='outlined'
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
      </>
    )
  }

  function FormAccntTypeSelector() {
    const {handleChange, values} = useFormikContext()
    //
    const fieldName = 'acct_type'
    //
    return (
      <>
        <Grid item className={'mt-2'} xs={12} md={3}>
          <FormLabel className='gray-1-text' id='accounttype'>
            Account Type
          </FormLabel>
        </Grid>
        <Grid item xs={6} md={6}>
          <Select aria-label='Account Type' id='forhbo' labelId='accounttype-label' label='Select Account Type' name={fieldName} value={values[fieldName] || ''} onChange={handleChange(fieldName)}>
            <MenuItem key='Savings' value='Savings'>
              Savings
            </MenuItem>
            <MenuItem key='Checking' value='Checking'>
              Checking
            </MenuItem>
          </Select>
        </Grid>
      </>
    )
  }

  function FormPlanSelector() {
    //
    const {setFieldValue, values, errors, touched} = useFormikContext()
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={12}>
          <FormLabel className='gray-1-text' style={{fontWeight: 'bold'}} id='directdebitplans'>
            Direct Debit Plans
          </FormLabel>
        </Grid>

        {values.ltc !== 2 ? (
          <>
            <Grid item className={'mt-5'} xs={6} md={3}>
              <FormLabel className='gray-1-text' id='longtermcare-label'>
                Long Term Care
              </FormLabel>
            </Grid>
            <Grid item className={'mt-2'} xs={6} md={9}>
              <Checkbox aria-label='Long Term Care' name='ltc' checked={values.ltc === 1 ? true : false} onChange={() => setFieldValue('ltc', values.ltc === 1 ? 0 : 1)} />
              {touched.ltc && !!errors.ltc ? <p className='error-help'>{errors.ltc}</p> : ''}
            </Grid>
          </>
        ) : null}

        {values.life !== 2 ? (
          <>
            <Grid item className={'mt-5'} xs={6} md={3}>
              <FormLabel className='gray-1-text' id='life-label'>
                Life
              </FormLabel>
            </Grid>
            <Grid item className={'mt-2'} xs={6} md={9}>
              <Checkbox aria-label='Life' name='life' checked={values.life === 1 ? true : false} onChange={() => setFieldValue('life', values.life === 1 ? 0 : 1)} />
              {touched.life && !!errors.life ? <p className='error-help'>{errors.life}</p> : ''}
            </Grid>
          </>
        ) : null}

        {values.dental !== 2 ? (
          <>
            <Grid item className={'mt-5'} xs={6} md={3}>
              <FormLabel className='gray-1-text' id='dental-label'>
                Dental
              </FormLabel>
            </Grid>
            <Grid item className={'mt-2'} xs={6} md={9}>
              <Checkbox aria-label='Dental' name='dental' checked={values.dental === 1 ? true : false} onChange={() => setFieldValue('dental', values.dental === 1 ? 0 : 1)} />
              {touched.dental && !!errors.dental ? <p className='error-help'>{errors.dental}</p> : ''}
            </Grid>
          </>
        ) : null}

        {values.disability !== 2 ? (
          <>
            <Grid item className={'mt-5'} xs={6} md={3}>
              <FormLabel className='gray-1-text' id='disability-label'>
                Disability
              </FormLabel>
            </Grid>
            <Grid item className={'mt-2'} xs={6} md={9}>
              <Checkbox aria-label='Disability' name={'disability'} checked={values.disability === 1 ? true : false} onChange={() => setFieldValue('disability', values.disability === 1 ? 0 : 1)} />
              {touched.disability && !!errors.disability ? <p className='error-help'>{errors.disability}</p> : ''}
            </Grid>
          </>
        ) : null}

        {values.ci !== 2 ? (
          <>
            <Grid item className={'mt-5'} xs={6} md={3}>
              <FormLabel className='gray-1-text' id='criticalillness-label'>
                Critical Illness
              </FormLabel>
            </Grid>
            <Grid item className={'mt-2'} xs={6} md={9}>
              <Checkbox aria-label='Critical Illness' name='ci' checked={values.ci === 1 ? true : false} onChange={() => setFieldValue('ci', values.ci === 1 ? 0 : 1)} />
              {touched.ci && !!errors.ci ? <p className='error-help'>{errors.ci}</p> : ''}
            </Grid>
          </>
        ) : null}

        {values.adnd !== 2 ? (
          <>
            <Grid item className={'mt-5'} xs={6} md={3}>
              <FormLabel className='gray-1-text' id='adnd-label'>
                ADND
              </FormLabel>
            </Grid>
            <Grid item className={'mt-2'} xs={6} md={9}>
              <Checkbox aria-label='Accidental Death and Dismemberment' name='adnd' checked={values.adnd === 1 ? true : false} onChange={() => setFieldValue('adnd', values.adnd === 1 ? 0 : 1)} />
              {touched.adnd && !!errors.adnd ? <p className='error-help'>{errors.adnd}</p> : ''}
            </Grid>
          </>
        ) : null}
      </>
    )
  }

  function FormPlanEnd() {
    //
    const {setFieldValue, values, errors, touched} = useFormikContext()
    //
    // Check if there an existing plan
    if (!values.id || values.id === 0) {
      return null
    }
    //

    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={12}>
          <FormLabel className='gray-1-text' style={{fontWeight: 'bold'}} id='directdebitplancancellation'>
            Cancel Direct Debit
          </FormLabel>
        </Grid>
        <Grid item className={'mt-5 bg-light-danger pt-8 pb-8 rounded'} xs={6} md={3}>
          <FormLabel className='warning-text' id='endDirectDebit-label'>
            Discontinue Direct Debit
          </FormLabel>
        </Grid>
        <Grid item className={'mt-5 bg-light-danger pt-6 pb-8 rounded'} xs={6} md={9}>
          <Checkbox
            aria-label='End Direct Debit'
            name='endDirectDebit'
            checked={values.endDirectDebit === 1 ? true : false}
            onChange={() => setFieldValue('endDirectDebit', values.endDirectDebit === 1 ? 0 : 1)}
          />
          <FormLabel className='warning-text' id='endDirectDebitWarning-label'>
            (Selecting this will Discontinue Direct Debit for All Plans)
          </FormLabel>
          {touched.endDirectDebit && !!errors.endDirectDebit ? <p className='error-help'>{errors.endDirectDebit}</p> : ''}
        </Grid>
      </>
    )
  }

  function FormSigningInformation() {
    //
    // This is not a question - just some additional information
    //
    const {values} = useFormikContext()
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={12}>
          <FormLabel className='gray-1-text' style={{fontWeight: 'bold'}} id='directdebitplancancellation'>
            Authorization
          </FormLabel>
        </Grid>
        {values.endDirectDebit === 1 ? (
          <div className='mb-5 bg-light-danger p-8 rounded'>
            <strong>
              <u>BY SIGNING BELOW: </u>
            </strong>{' '}
            &nbsp; &nbsp; I <strong>{values.name}</strong> want to discontinue Direct Debit for <strong>ALL</strong> of my plans.
          </div>
        ) : (
          <div className='mb-5 bg-light-warning p-8 rounded'>
            <strong>
              <u>BY SIGNING BELOW: </u>
            </strong>{' '}
            &nbsp; &nbsp; I <strong>{values.name}</strong> authorize AFSPA to deduct insurance premiums each billing period for the coverages I have indicated. The authorization shall remain in effect
            until I revoke them in writing (email, letter, fax) or update this form on the Member Portal. (You can add additional plans, or terminate online.)
          </div>
        )}
      </>
    )
  }

  function SuspendedDirectDebit() {
    //
    return (
      <>
        <div className='mb-5 bg-light-danger p-8 rounded'>
          <strong>Direct Debit Suspended &nbsp; &nbsp;</strong>
          {directDebit.issue}
        </div>
      </>
    )
  }

  const validationSchema = Yup.object().shape({
    bank_name: Yup.string().min(2, 'Too Short').max(200, 'Too Long').required('City is required'),
    bank_city: Yup.string().min(2, 'Too Short').max(200, 'Too Long').required('City is required'),
    bank_state: Yup.string().ensure().required('State is required'),
    aba_num: Yup.string().min(2, 'Too Short').max(200, 'Too Long').required('Routing is required'),
    acct_num: Yup.string().min(2, 'Too Short').max(200, 'Too Long').required('Account Number is required'),
    acct_type: Yup.string().ensure().required('Account Type is required')
  })

  function DisplayQuestions() {
    //
    return (
      <React.Fragment>
        <FormBankNameSelector />
        <FormBankCitySelector />
        <FormBankStateSelector />
        <Divider style={{width: '100%', marginTop: 20, marginBottom: 20}} />
        <FormBankABANumSelector />
        <FormBankAccntNumSelector />
        <FormAccntTypeSelector />
        <Divider style={{width: '100%', marginTop: 20, marginBottom: 20}} />
        <FormPlanSelector />
        <Divider style={{width: '100%', marginTop: 20, marginBottom: 20}} />
        <FormPlanEnd />
        <Divider style={{width: '100%', marginTop: 20, marginBottom: 20}} />
        <FormSigningInformation />
      </React.Fragment>
    )
  }

  function ErrorsNotification({errors, touched}) {
    if (errors && Object.keys(errors).length > 0 && touched && Object.keys(touched).length > 0) {
      return (
        <>
          <Card.Body>
            <div className='errors-list'>
              <h4 className='errors-list-heading'>Please fix the following fields before you submit:</h4>
              <ul>
                {Object.keys(errors).map((msg) => (
                  <React.Fragment key={msg}>{touched[msg]}</React.Fragment>
                ))}
              </ul>
            </div>
          </Card.Body>
        </>
      )
    } else {
      return null
    }
  }

  // Set defaults
  // var defaultV = {}
  // defaultV['ReasonEntry'] = ''
  return (
    <>
      {directDebit && directDebit.issue && directDebit.issue.length > 0 ? (
        <>
          <SuspendedDirectDebit />
        </>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={directDebit}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setIsLoading(true)
            Savesubmit(values)
          }}
        >
          {(props) => {
            const {errors, touched, handleSubmit} = props
            return (
              <form
                noValidate={true}
                autoComplete='off'
                onSubmit={(e) => {
                  e.preventDefault()
                  if (e.type !== 'keydown' || (e.target && e.target.type === 'submit')) {
                    handleSubmit(e)
                  }
                }}
              >
                <Row className='mb-5'>
                  <Col xs={12} md={9}>
                    <Grid container spacing={1}>
                      <p className='direct-debit-italic mt-5'>
                        I hereby authorize AFSPA to initiate Debit entries and, if necessary, to initiate Credit entries and/or adjustments for any Debit entries in error to my bank account indicated
                        below at the financial institution named below. Deductions shall be determined by the insurance coverage effective date and made on the 30th of each billing period. Allow 2
                        business days from the 30th for deductions to occur from your account (savings or checking). The first Deduction for a new insurance plan may be a partial amount adjusted for
                        when coverage begins and occur in our next Direct Debit billing cycle.
                      </p>
                    </Grid>
                  </Col>
                </Row>
                <Row className='mb-5'>
                  <Col xs={12} md={9}>
                    <Card>
                      <Card.Body>
                        <Grid container spacing={1}>
                          <DisplayQuestions />
                        </Grid>
                      </Card.Body>
                      <Divider />
                      <ErrorsNotification errors={errors} touched={touched} />

                      <Card.Body>
                        <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                          <Grid container xs={12} gap={3} justifyContent={'end'}>
                            <Link to='/home'>
                              <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                                Cancel
                              </Button>
                            </Link>
                            <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation disabled={isLoading} style={{background: '#0074A6'}}>
                              Click to Sign
                            </Button>
                          </Grid>
                        </Box>

                        <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                          <Grid container xs={12} justifyContent={'space-between'}>
                            <Grid item className={'pr-2'} xs={6}>
                              <Link to='/home'>
                                <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                                  Cancel
                                </Button>
                              </Link>
                            </Grid>
                            <Grid item className={'pl-2'} xs={6}>
                              <Button variant='contained' size={'large'} className={'w-100'} color='primary' type='submit' disableElevation disabled={isLoading} style={{background: '#0074A6'}}>
                                Save Information
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </form>
            )
          }}
        </Formik>
      )}

      <Dialog open={open} aria-labelledby='Submitting Claim Please Wait' fullWidth={true} maxWidth='sm'>
        <DialogTitle>Submitting Direct Debit Information</DialogTitle>
        <DialogContent style={{paddingTop: 30, paddingBottom: 30}}>
          {/* Check for result of submitting claim */}
          {returnStatus && returnStatus.length > 0 ? (
            <>
              {returnStatus === 'OK' ? (
                <Card>
                  <Card.Body>
                    <div className='mb-2 bg-light-primary p-8 rounded self-stretch'>
                      <div className='text-primary'>
                        <strong>Direct Debit Update Submitted Successfully</strong>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                        <Link to='/home'>
                          <Button variant='contained' size={'large'} color='primary' disableElevation style={{background: '#0074A6'}}>
                            Return to Home
                          </Button>
                        </Link>
                      </Grid>
                    </Box>

                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                      <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid item className={'pr-2'} xs={6}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}}>
                              Return to Home
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card.Body>
                </Card>
              ) : (
                <Card>
                  <Card.Body>
                    <div className='mb-2 bg-light-danger p-8 rounded self-stretch'>
                      <div className='text-danger'>
                        <strong>{returnStatus}</strong>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                        <Link to='/home'>
                          <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                            Return to Home
                          </Button>
                        </Link>
                        <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                          Edit your Direct Debit Information
                        </Button>
                      </Grid>
                    </Box>

                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                      <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid item className={'pr-2'} xs={6}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                              Return to Home
                            </Button>
                          </Link>
                        </Grid>
                        <Grid item className={'pl-2'} xs={6}>
                          <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                            Edit your Direct Debit Information
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card.Body>
                </Card>
              )}
            </>
          ) : (
            <DialogContentText>
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait while we process your submission... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
              </span>
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
