import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import IncentivesHistoryTable from '../../components/Tables/IncentivesHistoryTable'
import {NavLink} from 'react-router-dom'

export default function ClaimsHistoryDashboard() {
  // Determine AETNA SSO Login
  const aetnasso = useSelector((state: any) => state.member.AETNA)
  const [requestAetnaSSO, setrequestAetnaSSO] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!aetnasso && !requestAetnaSSO) {
      dispatch(Member.actions.fetchAETNA())
      setrequestAetnaSSO(true)
    }
  }, [dispatch, aetnasso, requestAetnaSSO])

  const title = 'Incentives History'

  return (
    <DashboardBox hasMobileFullWidth>
      <DashboardSection
        name={title}
        className='table_box_xl_device'
        button={
          <NavLink to='/submit-incentive'>
            <Button variant='contained' startIcon={<AddIcon />} disableElevation>
              New Incentive
            </Button>
          </NavLink>
        }
      >
        <div className='text-start flex flex-col items-start justify-start gap-2.5'>
          <p className='dashboard-text'>
            {`FSBP incentive claims are also normally available to view within 20 business days via `}
            <span style={{textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue'}}>
              <a href={aetnasso && aetnasso.cookieparameters} target='_blank' rel='noopener noreferrer'>
                {'Single Sign On (SSO) to AETNA.'}
              </a>
            </span>
          </p>
          <p className='dashboard-text'>{`Your FSBP Incentive Claim uploads are displayed below and are available for 24 months from your termination date.`}</p>
        </div>
        <IncentivesHistoryTable />
      </DashboardSection>
      <div className='table_box_mobile w-full'>
        <DashboardSection
          name={title}
          button={
            <div role='button' className='circle_button_icon hover:brightness-90'>
              <AddIcon style={{color: 'white', fontSize: '26px'}} />
            </div>
          }
        >
          <div className='w-full h-4'></div>
        </DashboardSection>
        <IncentivesHistoryTable displayForMobile />
      </div>
    </DashboardBox>
  )
}
