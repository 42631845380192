import {useEffect, useState, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import {saveAs} from 'file-saver'
import {Buffer} from 'buffer'
import MobileIncentiveTable from './MobileTable'
import Spinner from 'react-bootstrap/Spinner'
import DesktopIncentivesTable from './DesktopIncentivesTable'
import {styled} from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion'
import MuiAccordionSummary, {AccordionSummaryProps} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({theme}) => ({
  width: '100%',
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}} />} {...props} />)(({theme}) => ({
  width: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  width: '100%',
  padding: theme.spacing(2),
  borderTop: '2px solid rgba(0, 0, 0, .125)'
}))

export interface Incentive {
  id: number
  created: string
  reason: string
  type: string
  filename: string
  incentiveyear: string
}

interface IncentivesHistoryTableProps {
  displayForMobile?: boolean
}
export default function IncentivesHistoryTable({displayForMobile}: IncentivesHistoryTableProps) {
  const [expanded, setExpanded] = useState<string | false>('')
  const [downloadStatus, setDownloadStatus] = useState('Not Started')
  const [downloadFileName, setDownloadFileName] = useState('')
  const incentiveDownload = useSelector((state: any) => state.member.Incentive)
  // Map Incentives to Redux State
  const incentives = useSelector((state: any) => state.member.Incentives)
  const incentiveCheck = useSelector((state: any) => state.member.IncentiveCheck)
  const dispatch = useDispatch()

  let yearList

  const yearListDuplicates = incentives && incentives.length > 0 ? incentives.map((incentive: Incentive) => incentive.incentiveyear) : ''
  yearList =
    yearListDuplicates && yearListDuplicates.length > 0
      ? yearListDuplicates.reduce(function (a: any, b: any) {
          if (a.indexOf(b) < 0) a.push(b)
          return a
        }, [])
      : ''

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const downloadIncentive = (incentive: Incentive) => {
    // console.log(claim)
    if (downloadStatus === 'Not Started') {
      dispatch(Member.actions.fetchIncentive({incentiveID: incentive.id, filename: incentive.filename}))
      setDownloadFileName(incentive.filename)
      setDownloadStatus('Fetching')
    }
  }

  // grab the blob from state and tell the browser to download
  const downloadIncentiveFile = useCallback(() => {
    if (incentiveDownload && incentiveDownload !== '') {
      const file = new Blob([Buffer.from(incentiveDownload, 'base64')], {type: 'application/pdf'})
      saveAs(file, downloadFileName)
    }
  }, [downloadFileName, incentiveDownload])

  // useEffect to fire when status is 'Fetching' and we get the result in redux
  useEffect(() => {
    if (downloadStatus === 'Fetching' && incentiveDownload && incentiveDownload !== '') {
      downloadIncentiveFile()
      setDownloadStatus('Not Started')
      dispatch(Member.actions.clearIncentive())
    }
  }, [downloadStatus, downloadIncentiveFile, incentiveDownload, dispatch])

  // Fetch incentives
  useEffect(() => {
    dispatch(Member.actions.fetchIncentives())
  }, [dispatch])

  // Check if data still be retrieved
  if (Object.keys(incentives).length === 0 && !incentiveCheck) {
    return (
      <span className='indicator-progress' style={{display: 'block'}}>
        <p className='claim-text mb-5'>
          Please wait while we retrieve your incentive claims history... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
        </p>
      </span>
    )
  }

  // No data is found
  if (Object.keys(incentives).length === 0 && incentiveCheck) {
    return (
      <p className='claim-text mb-10'>
        <strong>No incentives found</strong>
      </p>
    )
  }

  if (displayForMobile) {
    return <MobileIncentiveTable incentiveType='Incentive' onClickDownload={downloadIncentive} data={incentives} />
  } else {
    return yearList && yearList.length > 0
      ? yearList.map((year: string) => {
          return (
            <Accordion expanded={expanded === year} onChange={handleChange(year)} key={year}>
              <AccordionSummary aria-controls={year} id={year}>
                <Typography>{year}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DesktopIncentivesTable data={incentives} onClickDownload={downloadIncentive} year={year} />
              </AccordionDetails>
            </Accordion>
          )
        })
      : 'None'
  }
}
