import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Totp} from './components/Totp'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import {LostPasswordUpdate} from './components/LostPasswordUpdate'
import {DashboardAuth} from './components/DashboardAuth'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='totp' element={<Totp />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password/:pagetype' element={<ForgotPassword />} />
      <Route path='lostpasswordupdate/:lostpasswordguid' element={<LostPasswordUpdate />} />
      <Route path='register/:registerguid' element={<LostPasswordUpdate />} />
      <Route path='reset/:resetguid' element={<LostPasswordUpdate />} />
      <Route path='dashboardauth' element={<DashboardAuth />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
