// /* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import md5 from '../../../helpers/Misc'
import * as Auth from '../../../store/ducks/auth.duck'
import {Button} from '@mui/material'
import * as Member from '../../../store/ducks/member.duck'
import * as Entity from '../../../store/ducks/entity.duck'

const loginSchema = Yup.object().shape({
  username: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Username is required'),
  password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required')
})

const initialValues = {
  username: '',
  password: ''
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login(props: any) {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const intl = useIntl()
  const accreditationDisplay = useSelector((state: any) => state.member.Flags.AccreditationDisplay)
  const upcomingMaintenanceDisplay = useSelector((state: any) => state.member.Flags.UpcomingMaintenanceDisplay)
  const upcomingMaintenanceMessage = useSelector((state: any) => state.member.Flags.UpcomingMaintenanceMessage)
  const outageDisplay = useSelector((state: any) => state.member.Flags.OutageDisplay)
  const outageMessage = useSelector((state: any) => state.member.Flags.OutageMessage)
  const bypassStatus = useSelector((state: any) => state.member.Flags.BypassStatus)
  const [requestFlags, setRequestFlags] = useState(false)
  const searchParams = useLocation().search
  const bypassToken = new URLSearchParams(searchParams).get('backdoor_pass')

  useEffect(() => {
    dispatch(Auth.actions.logout())
    dispatch(Member.actions.setMemberInitialState())
    dispatch(Entity.actions.setEntityInitialState())

    if (!requestFlags) {
      dispatch(Member.actions.fetchFlags({bypassToken: bypassToken ? bypassToken : null}))
      setRequestFlags(true)
    }
  }, [dispatch, requestFlags, setRequestFlags, bypassToken])

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const enc_password = md5(values.password)
        const {data: auth} = await login(values.username, enc_password)
        dispatch(Auth.actions.login(auth))
        console.log('auth')
        console.log(auth)

        if (auth.status === '2FA') {
          console.log('2FA')
          navigate('/auth/totp')
        } else {
          dispatch(Auth.actions.getVHLogin(auth.member_key, auth.token, auth.email))
        }
      } catch (err: any) {
        // console.log(err}
        //console.log(err.response.data)
        // console.log(err.response.status)
        // const message = '<br> Hello World <br>'
        setSubmitting(false)
        setLoading(false)
        if (err) {
          if (err.response) {
            if (err.response.data.message && err.response.data.message === 'Access Locked') {
              setStatus(
                <a href='https://www.afspa.org/secure-form-member-portal-support/' target='_blank' rel='noreferrer'>
                  Your account is locked. Please click here to contact AFSPA for assistance
                </a>
              )
            } else {
              if (err.response.data.message && err.response.data.message === 'Bad') {
                setStatus(
                  intl.formatMessage({
                    id: 'AUTH.VALIDATION.SERVER_OUTAGE'
                  })
                )
              } else {
                if (err.response.data.message && err.response.data.message === 'Outage') {
                  setStatus(outageMessage)
                } else {
                  if (err.response.data.message && err.response.data.message === 'Account Locked') {
                    setStatus(
                      intl.formatMessage({
                        id: 'AUTH.VALIDATION.LOCKOUT_LOGIN'
                      })
                    )
                  } else {
                    setStatus(
                      intl.formatMessage({
                        id: 'AUTH.VALIDATION.INVALID_LOGIN'
                      })
                    )
                  }
                }
              }
            }
          } else {
            setStatus(
              intl.formatMessage({
                id: 'AUTH.VALIDATION.SERVER_OUTAGE'
              })
            )
          }
        } else {
          setStatus(
            intl.formatMessage({
              id: 'AUTH.VALIDATION.SERVER_OUTAGE'
            })
          )
        }
        saveAuth(undefined)
        // setStatus('The login details are incorrect')
      }
    }
  })

  return (
    <>
      {outageDisplay && !bypassStatus ? (
        <div className='mb-5 bg-light-danger p-8 rounded'>
          <div className='text-danger'>{outageMessage}</div>
        </div>
      ) : (
        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
          {/* begin::Heading */}
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
          </div>
          {/* begin::Heading */}

          {/* begin::Altert of Notice of Accreditation Survey for the Foreign Service Benefit Plan */}
          {accreditationDisplay && !outageDisplay && !upcomingMaintenanceDisplay ? (
            <div className='mb-5 bg-light-primary p-8 rounded'>
              <div className='text-primary'>Notice of Accreditation Survey for the Foreign Service Benefit Plan</div>
              <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold'>
                <div />
                <Link
                  className='nav-link pr-3 pl-0'
                  to={{pathname: 'https://www.afspa.org/newsitems/aaahc-accreditation-association-for-ambulatory-health-care-notice-of-public-survey/'}}
                  target='_blank'
                >
                  <div style={{display: 'inline-block'}}>
                    <Button variant='outlined' color='secondary' style={{background: '#daeaf5', marginTop: 5}}>
                      More Information
                    </Button>
                  </div>
                </Link>
              </div>
            </div>
          ) : null}
          {/* begin::Notice of Accreditation Survey for the Foreign Service Benefit Plan */}

          {/* begin::Alert of Upcoming Maintenance */}
          {upcomingMaintenanceDisplay && !outageDisplay ? (
            <div className='mb-5 bg-light-warning p-8 rounded'>
              <div className='text-warning'>{upcomingMaintenanceMessage}</div>
            </div>
          ) : null}
          {/* begin::Alert of Upcoming Maintenance */}

          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : null}

          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
            <input
              placeholder='Username'
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.username && formik.errors.username},
                {
                  'is-valid': formik.touched.username && !formik.errors.username
                }
              )}
              type='text'
              name='username'
              autoComplete='off'
            />
            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.username}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            <input
              type='password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Wrapper */}
          <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
            <div />

            {/* begin::Link */}
            <Link to='/auth/forgot-password/forgot' className='link-primary'>
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
          {/* end::Wrapper */}

          {/* begin::Action */}
          <div className='d-grid mb-10'>
            <button type='submit' id='kt_sign_in_submit' className='btn btn-primary' disabled={formik.isSubmitting || !formik.isValid}>
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}

          <div className='text-gray-500 text-center fw-semibold fs-6'>
            Not a Member yet?{' '}
            <Link to='/auth/forgot-password/register' className='link-primary'>
              Register
            </Link>
          </div>
        </form>
      )}
    </>
  )
}
