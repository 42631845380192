import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Grid, Divider, Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, Box} from '@mui/material'
import {Link} from 'react-router-dom'
import {object, ref, string} from 'yup'
import {Formik} from 'formik'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import CheckIcon from '@mui/icons-material/CheckOutlined'
import Spinner from 'react-bootstrap/Spinner'
import * as Auth from '../../store/ducks/auth.duck'
import md5 from '../../components/Misc/Md5'

export default function PasswordUpdateForm() {
  //

  // Set open/close state on note add diaglog box
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const returnStatus = useSelector((state) => state.member.ReturnStatus)

  // const handleClose = () => {
  //   setOpen(false)
  // }

  function allowMoreEditing() {
    // Close Submitting Modal
    setOpen(false)
    // Reactivate submit for another attempt
    setIsLoading(false)
  }

  // useEffect(() => {
  //   //
  //   // Some Commentary about this
  //   // Essentially when someone creates a submits a form, a processing window appears and disappears once the form is processed
  //   // To do this a Redux variable - <formtype>Form  is set with a number - null is not set, 1 is processing and 2 is an error state
  //   //
  //   if (formStatus) {
  //     checkFormStatus(formStatus)
  //   }
  // })

  // function checkFormStatus(newFormStatus) {
  //   if (newFormStatus === 1) {
  //     // Ok processed finished successfully - lets go
  //     // Close Submitting Modal
  //     setOpen(false)
  //     // Reset form status
  //     dispatch(Auth.actions.accountformclear())
  //     //
  //     handleClose()
  //   }
  //   if (newFormStatus === 2) {
  //     // it has errors - ditch the processing window and stay on page
  //     // console.log('Process finished badly');
  //     // Close Submitting Modal
  //     setOpen(false)
  //     // Reactivate submit for another attempt
  //     setIsLoading(false)
  //     // Reset form status
  //     dispatch(Auth.actions.accountformclear())
  //   }
  // }

  // Need to consider further whether someone can alter their email address and password at the same time?
  //
  const dispatch = useDispatch()
  const username = useSelector((state) => state.auth.username)

  //
  //
  function Savesubmit(data) {
    // Submit form
    // Open Submitting modal
    setOpen(true)
    setIsLoading(true)
    // MD5 hashpasswords before sending
    data['newPass'] = md5(data['newPass'])
    data['currentPass'] = md5(data['currentPass'])
    data['confirmPass'] = md5(data['confirmPass'])
    // console.log(data);
    dispatch(Auth.actions.putAccountInformation(data))
  }

  // Set defaults
  const defaultV = {}
  defaultV['currentPass'] = ''
  defaultV['newPass'] = ''
  defaultV['confirmPass'] = ''
  defaultV['username'] = username

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={defaultV}
        validationSchema={object().shape({
          username: string().required('Username is required'),
          currentPass: string().required('Current password is required'),
          newPass: string()
            .required('New password is required')
            .max(65, 'Too Long')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, 'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number'),
          confirmPass: string()
            .oneOf([ref('newPass')], 'Passwords do not match')
            .required('Password is required')
        })}
        onSubmit={(values, actions) => {
          // console.log('Test');
          setIsLoading(true)
          Savesubmit(values)
          actions.setSubmitting(false)
        }}
      >
        {(props) => {
          const {values, errors, handleChange, handleSubmit} = props
          const change = (name, e) => {
            e.persist()
            handleChange(e)
          }
          return (
            <form
              noValidate={true}
              autoComplete='off'
              onSubmit={(e) => {
                e.preventDefault()
                if (e.type !== 'keydown' || (e.target && e.target.type === 'submit')) {
                  handleSubmit(e)
                }
              }}
            >
              <Row className='mb-5'>
                <Col xs={12} md={9}>
                  <Card>
                    <Card.Body>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            type='text'
                            variant='outlined'
                            label='Username'
                            margin='normal'
                            name='username'
                            readOnly
                            value={values.username}
                            autoComplete='new-password'
                            onChange={change.bind(null, 'username')}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            type='password'
                            variant='outlined'
                            label='Current Password'
                            margin='normal'
                            name='currentPass'
                            value={values.currentPass}
                            helperText={errors.currentPass}
                            error={Boolean(errors.currentPass)}
                            autoComplete='new-password'
                            InputProps={{classes: {input: 'input-profile'}}}
                            onChange={change.bind(null, 'currentPass')}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            type='password'
                            variant='outlined'
                            label='New Password'
                            margin='normal'
                            name='newPass'
                            value={values.newPass}
                            helperText={errors.newPass}
                            error={Boolean(errors.newPass)}
                            autoComplete='new-password'
                            InputProps={{classes: {input: 'input-profile'}}}
                            onChange={change.bind(null, 'newPass')}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            type='password'
                            variant='outlined'
                            label='Confirm Password'
                            margin='normal'
                            name='confirmPass'
                            value={values.confirmPass}
                            helperText={errors.confirmPass}
                            error={Boolean(errors.confirmPass)}
                            autoComplete='new-password'
                            InputProps={{classes: {input: 'input-profile'}}}
                            onChange={change.bind(null, 'confirmPass')}
                          />
                        </Grid>
                      </Grid>
                    </Card.Body>

                    <Divider />
                    <Card.Body>
                      <Grid container direction='row' justifyContent='space-between'>
                        <Grid item md={3} xs={6}>
                          <Link to='/home'>
                            <Button variant='outlined' style={{background: '#eeeeee'}} startIcon={<CancelIcon />} disableElevation color='secondary'>
                              Cancel
                            </Button>
                          </Link>
                        </Grid>
                        <Grid item md={3} xs={6} style={{textAlign: 'right'}}>
                          <Button variant='outlined' color='primary' type='submit' startIcon={<CheckIcon />} disableElevation disabled={isLoading} style={{background: '#daeaf5'}}>
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </form>
          )
        }}
      </Formik>

      <Dialog open={open} aria-labelledby='Saving Password Change Please Wait' fullWidth={true} maxWidth='sm'>
        <DialogTitle>Saving Password Change</DialogTitle>
        <DialogContent style={{paddingTop: 30, paddingBottom: 30}}>
          {/* Check for result of submitting claim */}
          {returnStatus && returnStatus.length > 0 ? (
            <>
              {returnStatus === 'OK' ? (
                <Card>
                  <Card.Body>
                    <div className='mb-2 bg-light-primary p-8 rounded self-stretch'>
                      <div className='text-primary'>
                        <strong>Password Updated</strong>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                        <Link to='/home'>
                          <Button variant='contained' size={'large'} color='primary' disableElevation style={{background: '#0074A6'}}>
                            Return to Home
                          </Button>
                        </Link>
                      </Grid>
                    </Box>

                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                      <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid item className={'pr-2'} xs={6}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}}>
                              Return to Home
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card.Body>
                </Card>
              ) : (
                <Card>
                  <Card.Body>
                    <div className='mb-2 bg-light-danger p-8 rounded self-stretch'>
                      <div className='text-danger'>
                        <strong>{returnStatus}</strong>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                        <Link to='/home'>
                          <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                            Return to Home
                          </Button>
                        </Link>
                        <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                          Edit and Resubmit Password Update
                        </Button>
                      </Grid>
                    </Box>

                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                      <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid item className={'pr-2'} xs={6}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                              Return to Home
                            </Button>
                          </Link>
                        </Grid>
                        <Grid item className={'pl-2'} xs={6}>
                          <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                            Edit and Resubmit Password Update
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card.Body>
                </Card>
              )}
            </>
          ) : (
            <DialogContentText>
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait while we update your password... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
              </span>
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
