import React, {useEffect, useState, useCallback} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {
  Grid,
  Divider,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Checkbox,
  Radio,
  RadioGroup,
  Box
} from '@mui/material'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import {Formik, useFormikContext} from 'formik'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import * as Entity from '../../store/ducks/entity.duck'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import CheckIcon from '@mui/icons-material/CheckOutlined'
import {useDropzone} from 'react-dropzone'
import * as Yup from 'yup'
import {FormLabel} from 'react-bootstrap'

export default function SecureForm() {
  // Set requestType name from calling function
  const location = useLocation()
  const requestTypeID = location.state.requestTypeID
  let fileUpload
  let fileHandler = []
  let nfileUpload = ''

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formLoaded, setformLoaded] = useState(false)

  // useEffect(() => {
  //   //
  //   // Some Commentary about this
  //   // Essentially when someone creates a submits a form, a processing window appears and disappears once the form is processed
  //   // To do this a Redux variable - <formtype>Form  is set with a number - null is not set, 1 is processing and 2 is an error state
  //   //
  //   if (formStatus) {
  //     checkFormStatus(formStatus)
  //   }
  // })

  // function checkFormStatus(newFormStatus) {
  //   // console.log(newFormStatus);
  //   if (newFormStatus === 1) {
  //     // Ok processed finished successfully - lets go
  //     // console.log('Process finished successfully');
  //     // Close Submitting Modal
  //     setOpen(false)
  //     // Reset form status
  //     dispatch(Member.actions.formclear())
  //     // Back to the main menu
  //     navigate('/home')
  //   }
  //   if (newFormStatus === 2) {
  //     // it has errors - ditch the processing window and stay on page
  //     // console.log('Process finished badly');
  //     // Close Submitting Modal
  //     setOpen(false)
  //     // Reactivate submit for another attempt
  //     setIsLoading(false)
  //     // Reset form status
  //     dispatch(Member.actions.formclear())
  //   }
  // }

  function allowMoreEditing() {
    // Close Submitting Modal
    setOpen(false)
    // Reactivate submit for another attempt
    setIsLoading(false)
  }

  useEffect(() => {
    loadform()
  })
  function loadform() {
    if (!formLoaded) {
      dispatch(Entity.actions.fetchForm({requestTypeID, authVHToken}))
      setformLoaded(true)
      return true
    } else {
      return false
    }
  }

  const dispatch = useDispatch()
  const Forms = useSelector((state) => state.entity[location.state.requestTypeID])
  const authVHToken = useSelector((state) => state.auth.authVHToken)
  const authState = useSelector((state) => state.auth)
  const returnStatus = useSelector((state) => state.member.ReturnStatus)

  function generateFileHandle() {
    var d = new Date().getTime() //Timestamp
    var d2 = (performance && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0
        d = Math.floor(d / 16)
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0
        d2 = Math.floor(d2 / 16)
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
  }

  function Savesubmit(data) {
    // console.log(data)
    setOpen(true)
    setIsLoading(true)
    // Create structure to load file
    const formData = new FormData()
    // Add fields
    formData.append('formFullName', authState.firstName + ' ' + authState.lastName)
    formData.append('formEmail', authState.email)
    formData.append('requestTypeID', requestTypeID)
    // Check for file upload question
    if (fileUpload && (fileUpload?.length ?? 0) > 0 && data[fileUpload] && data[fileUpload].length) {
      // Ok there is a file upload question (will need to revisit this for forms with multiple file upload questions)
      // Lets loop through
      let arrayPointer = 0
      for (const fileUploadItem of data[fileUpload]) {
        // Generate unique-ish file handle to be used to connect the file and request
        const fileHandle = generateFileHandle()
        // Now add answer of file upload question - which is the file - to formData
        // Note the name files - must match the API otherwise nothing is received
        formData.append('files', fileUploadItem)
        // Now that file is loaded into formData, set fileupload question answer to the handle so the API can connect the file with the request
        data[fileUpload] = ''
        nfileUpload = fileUpload + '-' + arrayPointer.toString()
        data[nfileUpload] = ''
        // Set question answer to GUID i.e. Q147-0 = GUID
        data[nfileUpload] = fileHandle
        // Store GUID as it will be needed to reassociate files with questions at the Valhalla end
        fileHandler[arrayPointer] = fileHandle
        arrayPointer = arrayPointer + 1
      }
      formData.append('fileHandle', fileHandler)
      // Add Form Data
      // convert Formik values collection to an array for the API, and remove the 'Q' from the QuestionID
      var resultFiles = Object.keys(data).map((key) => {
        return {questionID: key, dataValue: data[key]}
      })
      formData.append('formInfo', JSON.stringify(resultFiles))
    } else {
      // Add Form Data
      // convert Formik values collection to an array for the API, and remove the 'Q' from the QuestionID
      data[fileUpload] = ''
      var resultNoFiles = Object.keys(data).map((key) => {
        return {questionID: key, dataValue: data[key]}
      })
      // console.log(resultNoFiles);
      formData.append('formInfo', JSON.stringify(resultNoFiles))
    }
    //
    // Save request/form
    dispatch(Member.actions.putFormData({formData}))
    // Return to home
    // props.history.push('/home');
  }

  function findDefault(nodeName) {
    switch (nodeName) {
      case 'email': {
        const email = authState.email
        return email
      }
      case 'fullName': {
        const firstName = authState.firstName
        const lastName = authState.lastName
        const fullName = firstName + ' ' + lastName
        return fullName
      }
      default:
        return ''
    }
  }

  function FormFile(question) {
    //
    const {setFieldValue, touched, errors} = useFormikContext()
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID
    // Set file upload parameter for submit function
    fileUpload = qname
    const [myFiles, setMyFiles] = useState([])

    const onDrop = useCallback(
      (acceptedFiles) => {
        setMyFiles([...myFiles, ...acceptedFiles])
        setFieldValue(qname, [...myFiles, ...acceptedFiles])
      },
      [myFiles, setFieldValue, qname]
    )

    const {getRootProps, getInputProps} = useDropzone({
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'image/jpg': [],
        'application/pdf': []
      },
      multiple: true,
      maxFiles: 20,
      maxFileSize: 6291457,
      onDrop
    })

    const removeFile = (file) => () => {
      const newFiles = [...myFiles]
      newFiles.splice(newFiles.indexOf(file), 1)
      setMyFiles(newFiles)
      setFieldValue(qname, [...newFiles])
    }

    const files = myFiles.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes <button onClick={removeFile(file)}>Remove File</button>
      </li>
    ))
    return (
      <Grid item xs={12} md={12}>
        <section className='container'>
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <p>Use Camera to capture documents or upload from device (max size 6 MB)</p>
          </div>
          <aside>
            <h4>Files</h4>
            <ul>{files}</ul>
          </aside>
        </section>
        {touched[qname] && !!errors[qname] ? <p className='error-help'>{errors[qname]}</p> : ''}
      </Grid>
    )
  }

  function FormVarchar(questions) {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    // Check if varchar question type
    if (questions.question.type !== 'VARCHAR') {
      return null
    }

    //
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + questions.question.questionID
    //
    return (
      <Grid item className={'mt-3'} xs={12} md={12}>
        <FormLabel className='gray-1-text' htmlFor={qname}>
          {questions.question.sectionText ? questions.question.sectionText : null}
        </FormLabel>
        <Grid item xs={6} md={6}>
          <TextField
            style={{minWidth: '100%'}}
            className={'mt-0'}
            type='text'
            label={questions.question.questionText}
            placeholder={questions.question.questionPlaceHolderText}
            variant='outlined'
            margin='normal'
            name={qname}
            value={values[qname] || ''}
            autoComplete='new-password'
            onChange={handleChange(qname)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[qname] ? errors[qname] : ''}
            error={touched[qname] && Boolean(errors[qname])}
          />
        </Grid>
      </Grid>
    )
  }

  function FormText(questions) {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    // Check if text question type
    if (questions.question.type !== 'TEXT') {
      return null
    }
    //
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + questions.question.questionID
    //
    return (
      <Grid item xs={12} md={9}>
        <label>{questions.question.questionText}</label>
        <TextField
          style={{margin: '14px 0 6px 0', minWidth: '100%'}}
          type='text'
          variant='outlined'
          placeholder={questions.question.questionPlaceHolderText}
          margin='normal'
          name={values[qname] || ''}
          value={values[qname] || ''}
          autoComplete='new-password'
          size='small'
          multiline
          rows={4}
          onChange={handleChange(qname)}
          InputProps={{classes: {input: 'input-profile'}}}
          helperText={touched[qname] ? errors[qname] : ''}
          error={touched[qname] && Boolean(errors[qname])}
        />
      </Grid>
    )
  }

  function FormOptionRadio(questions) {
    //
    const {handleChange, values} = useFormikContext()
    // Check if Option question and of type radio
    if (questions.question.type !== 'OPTION') {
      return null
    } else {
      if (questions.question.typeInput !== 'radio') {
        return null
      }
    }
    //
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + questions.question.questionID
    //
    return (
      <Grid item xs={12} md={9}>
        <label>{questions.question.questionText}</label>
        <RadioGroup aria-label={questions.question.questionText} name={values[qname] || ''} value={values[qname] || ''}>
          {questions.question.formOptions
            ? questions.question.formOptions.map((options) => {
                return <FormControlLabel key={options.optionID} value={options.optionID} control={<Radio />} label={options.optionLabel} onChange={handleChange(qname)} />
              })
            : 'None'}
        </RadioGroup>
      </Grid>
    )
  }

  function FormOptionSelect(questions) {
    //
    const {handleChange, values} = useFormikContext()
    // Check if Option question and of type select
    if (questions.question.type !== 'OPTION') {
      return null
    } else {
      if (questions.question.typeInput !== 'select') {
        return null
      }
    }
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + questions.question.questionID
    //
    return (
      <Grid item xs={12} md={9}>
        {/* <label>{props.question.questionText}</label> */}
        <FormControl variant='outlined' margin='dense' style={{margin: '14px 0 6px 0'}}>
          <InputLabel shrink id={qname + 'label'}>
            {questions.question.questionText}
          </InputLabel>
          <Select
            id={qname}
            labelId={qname + 'label'}
            label={questions.question.questionText}
            aria-label={questions.question.questionText}
            name={values[qname] || ''}
            value={values[qname] || ''}
            onChange={handleChange(qname)}
          >
            {questions.question.formOptions
              ? questions.question.formOptions.map((options) => {
                  return (
                    <MenuItem key={options.optionID} value={options.optionID}>
                      {options.optionLabel}
                    </MenuItem>
                  )
                })
              : 'None'}
          </Select>
        </FormControl>
      </Grid>
    )
  }

  function FormOptionCheckbox(questions) {
    //
    const {setFieldValue, values, touched, errors} = useFormikContext()
    // Check if Option question and of type select
    if (questions.question.type !== 'MULTIOPTION') {
      return null
    }
    const qname = 'Q' + questions.question.questionID
    const myValues = values[qname] || []

    const handleMyChange = (event) => {
      if (event.target.checked) {
        myValues.push(event.target.value)
      } else {
        var i = myValues.indexOf(event.target.value)
        myValues.splice(i, 1)
      }
      setFieldValue(qname, myValues)
    }

    return (
      <Grid item xs={12} md={9}>
        <label>{questions.question.questionText}</label>
        <FormControl component='fieldset'>
          <FormGroup row={questions.question.answerLayout === 'row' ? true : false}>
            {questions.question.formOptions
              ? questions.question.formOptions.map((options) => {
                  return (
                    <FormControlLabel
                      key={options.optionID}
                      control={<Checkbox name={qname} value={options.optionID} checked={myValues.some((v) => v === options.optionID)} onChange={handleMyChange} className='form-radio-input' />}
                      label={<Typography className={'form-radio-label'}>{options.optionLabel}</Typography>}
                      style={{marginBottom: 0, cursor: 'pointer'}}
                    />
                  )
                })
              : 'None'}
          </FormGroup>
          {touched[qname] && !!errors[qname] ? <p className='error-help'>{errors[qname]}</p> : ''}
        </FormControl>
      </Grid>
    )
  }

  function FormQuestions(question) {
    // Set form name from calling function
    return (
      <React.Fragment>
        <FormVarchar question={question.questions} />
        <FormText question={question.questions} />
        <FormOptionRadio question={question.questions} />
        <FormOptionSelect question={question.questions} />
        <FormOptionCheckbox question={question.questions} />
        {question.questions.type === 'FILE' ? <FormFile question={question.questions} /> : null}
      </React.Fragment>
    )
  }

  const prefix = 'Q'
  // Default Values need to be set at top level thus need an array of default values
  const defaultV = {}
  let defaultItem
  let defaultQuestion
  // Loop through questions
  if (Forms && Forms.formQuestions) {
    Forms.formQuestions.forEach((questions) => {
      defaultItem = findDefault(questions.nodeName)
      defaultQuestion = prefix.concat(questions.questionID)
      // Need to handle option and radio where nodename may no be populated
      if (questions.type === 'OPTION') {
        // Set default to be first option
        defaultItem = questions.formOptions[0].optionID
      }
      defaultV[defaultQuestion] = defaultItem
    })
  }

  var tempSchema = {}

  Forms &&
    Forms.formQuestions &&
    Forms.formQuestions.map(
      (question) =>
        (tempSchema = {
          ...tempSchema,
          [`Q${question.questionID}`]: question.mandatory
            ? question.type === 'VARCHAR' || question.type === 'TEXT'
              ? Yup.string().required('Answer is required')
              : question.type === 'MULTIOPTION'
              ? Yup.string().required(`Answer is required to ${question.reference}`)
              : null
            : null
        })
    )

  const validationSchema = Yup.object().shape(tempSchema)

  function ErrorsNotification({errors, touched}) {
    if (errors && Object.keys(errors).length > 0 && touched && Object.keys(touched).length > 0) {
      return (
        <>
          <Card.Body>
            <div className='errors-list'>
              <h4 className='errors-list-heading'>Please fix the following fields before you submit:</h4>
              <ul>
                {Object.keys(errors).map((msg) => (
                  <React.Fragment key={msg}>{touched[msg] ? <li>{errors[msg]}</li> : null}</React.Fragment>
                ))}
              </ul>
            </div>
          </Card.Body>
        </>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={defaultV}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          Savesubmit(values)
        }}
      >
        {(props) => {
          const {errors, touched, handleSubmit} = props
          // const change = (name,e) => {
          //   e.persist();
          //   console.log(e);
          //   console.log(name);
          //   handleChange(e);
          // };
          return (
            <form
              noValidate={true}
              autoComplete='off'
              onSubmit={(e) => {
                e.preventDefault()
                if (e.type !== 'keydown' || (e.target && e.target.type === 'submit')) {
                  handleSubmit(e)
                }
              }}
            >
              <Grid container justifyContent='center'>
                <Row className='mb-5'>
                  <Col xs={12} md={9}>
                    <Card>
                      <Card.Title>{Forms && Forms.formName}</Card.Title>
                      <Card.Body>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={12}>
                            <p>{Forms && Forms.formInstructions}</p>
                            <hr />
                          </Grid>

                          {Forms && Forms.formQuestions
                            ? Forms.formQuestions.map((question) => {
                                return <FormQuestions questions={question} key={question.questionID} />
                              })
                            : 'None'}
                        </Grid>
                      </Card.Body>
                      <Divider />
                      <ErrorsNotification errors={errors} touched={touched} />
                      <Card.Body>
                        <Grid container direction='row' justifyContent='space-between'>
                          <Grid item md={3} xs={6}>
                            <Link to='/home'>
                              <Button variant='outlined' style={{background: '#eeeeee'}} startIcon={<CancelIcon />} disableElevation color='secondary'>
                                Cancel
                              </Button>
                            </Link>
                          </Grid>
                          <Grid item md={3} xs={6} style={{textAlign: 'right'}}>
                            <Button variant='outlined' color='primary' startIcon={<CheckIcon />} disableElevation type='submit' disabled={isLoading} style={{background: '#daeaf5'}}>
                              Submit Form
                            </Button>
                          </Grid>
                        </Grid>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Grid>
            </form>
          )
        }}
      </Formik>

      <Dialog open={open} aria-labelledby='Submitting Secure Form' fullWidth={true} maxWidth='sm'>
        <DialogTitle>Submitting Secure Form</DialogTitle>
        <DialogContent style={{paddingTop: 30, paddingBottom: 30}}>
          {/* Check for result of submitting claim */}
          {returnStatus && returnStatus.length > 0 ? (
            <>
              {returnStatus === 'OK' ? (
                <Card>
                  <Card.Body>
                    <div className='mb-2 bg-light-primary p-8 rounded self-stretch'>
                      <div className='text-primary'>
                        <strong>Secure Form Submitted Successfully</strong>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                        <Link to='/home'>
                          <Button variant='contained' size={'large'} color='primary' disableElevation style={{background: '#0074A6'}}>
                            Return to Home
                          </Button>
                        </Link>
                      </Grid>
                    </Box>

                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                      <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid item className={'pr-2'} xs={6}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}}>
                              Return to Home
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card.Body>
                </Card>
              ) : (
                <Card>
                  <Card.Body>
                    <div className='mb-2 bg-light-danger p-8 rounded self-stretch'>
                      <div className='text-danger'>
                        <strong>{returnStatus}</strong>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                        <Link to='/home'>
                          <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                            Return to Home
                          </Button>
                        </Link>
                        <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                          Edit and Resubmit Form
                        </Button>
                      </Grid>
                    </Box>

                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                      <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid item className={'pr-2'} xs={6}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                              Return to Home
                            </Button>
                          </Link>
                        </Grid>
                        <Grid item className={'pl-2'} xs={6}>
                          <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                            Edit and Resubmit Form
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card.Body>
                </Card>
              )}
            </>
          ) : (
            <DialogContentText>
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait while we process your submission... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
              </span>
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
