import {useEffect, useState, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import {saveAs} from 'file-saver'
import {Buffer} from 'buffer'
import MobileIncentiveTable from './MobileTable'
import DesktopClaimsTable from './DesktopClaimsTable'
import Spinner from 'react-bootstrap/Spinner'
import {styled} from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion'
import MuiAccordionSummary, {AccordionSummaryProps} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({theme}) => ({
  width: '100%',
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}} />} {...props} />)(({theme}) => ({
  width: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  width: '100%',
  padding: theme.spacing(2),
  borderTop: '2px solid rgba(0, 0, 0, .125)'
}))

export interface Claim {
  id: number
  created: string
  comments: string
  trackingInformation: string
  filename: string
  claimyear: string
}

interface HistoryClaimsTableProps {
  displayForMobile?: boolean
}
export default function HistoryClaimsTable({displayForMobile = false}: HistoryClaimsTableProps) {
  const [expanded, setExpanded] = useState<string | false>('')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Member.actions.fetchClaims())
  }, [dispatch])

  // Map Claims to Redux State
  const claims = useSelector((state: any) => state.member.Claims)
  const claimCheck = useSelector((state: any) => state.member.ClaimCheck)
  const [downloadStatus, setDownloadStatus] = useState('Not Started')
  const [downloadFileName, setDownloadFileName] = useState('')
  const claimDownload = useSelector((state: any) => state.member.Claim)
  let yearList

  const yearListDuplicates = claims && claims.length > 0 ? claims.map((claim: Claim) => claim.claimyear) : ''
  yearList =
    yearListDuplicates && yearListDuplicates.length > 0
      ? yearListDuplicates.reduce(function (a: any, b: any) {
          if (a.indexOf(b) < 0) a.push(b)
          return a
        }, [])
      : ''

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const downloadClaim = (claim: Claim) => {
    // console.log(claim)
    if (downloadStatus === 'Not Started') {
      dispatch(Member.actions.fetchClaim({claimID: claim.id, filename: claim.filename}))
      setDownloadFileName(claim.filename)
      setDownloadStatus('Fetching')
    }
  }

  // grab the blob from state and tell the browser to download
  const downloadClaimFile = useCallback(() => {
    //   var exportDocName = `Project Schedule - ${header.name}`;
    if (claimDownload && claimDownload !== '') {
      const file = new Blob([Buffer.from(claimDownload, 'base64')], {type: 'application/pdf'})
      saveAs(file, downloadFileName)
    }
  }, [downloadFileName, claimDownload])

  // useEffect to fire when status is 'Fetching' and we get the result in redux
  useEffect(() => {
    if (downloadStatus === 'Fetching' && claimDownload && claimDownload !== '') {
      downloadClaimFile()
      setDownloadStatus('Not Started')
      dispatch(Member.actions.clearClaim())
    }
  }, [downloadStatus, downloadClaimFile, claimDownload, dispatch])

  // Check if data still be retrieved
  if (Object.keys(claims).length === 0 && !claimCheck) {
    return (
      <span className='indicator-progress' style={{display: 'block'}}>
        <p className='claim-text mb-5'>
          Please wait while we retrieve your claims history... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
        </p>
      </span>
    )
  }

  // No data is found
  if (Object.keys(claims).length === 0 && claimCheck) {
    return (
      <p className='claim-text mb-10'>
        <strong>No claims found</strong>
      </p>
    )
  }

  if (displayForMobile) {
    return <MobileIncentiveTable incentiveType='Claim' onClickDownload={downloadClaim} data={claims} />
  } else {
    return yearList && yearList.length > 0
      ? yearList.map((year: string) => {
          return (
            <Accordion expanded={expanded === year} onChange={handleChange(year)} key={year}>
              <AccordionSummary aria-controls={year} id={year}>
                <Typography>{year}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DesktopClaimsTable data={claims} onClickDownload={downloadClaim} year={year} />
              </AccordionDetails>
            </Accordion>
          )
        })
      : 'None'
  }
}
